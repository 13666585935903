<template>
    <div class="module">
        <h1 class="module-header">{{ array[0] }}</h1>
        <div v-for="item in array" :key="item.id">
            <a class="module-element" :href="item.url" target='_blank'>{{ item.header }}</a>
        </div>
        <a class="module-element" :href="more" target='_blank'>more...</a>
    </div>
</template>

<script>
export default {
    props: ['array', 'more']
};
</script>

<style scoped>
.module {
    display: flex;
    flex-direction: column;
    box-shadow: 0 .125rem .25rem rgba(255, 255, 255, 0.075);
    width: 30%;
    padding: 35px 35px 5px 35px;
    background-color: rgba(0, 0, 0, 0.158);
    border-radius: 5px;
}

.module-element {
    text-decoration: none;
    color: gray;
    transition: 0.2s all;
}

.module-element:hover {
    color: white;
}

.module-header {
    font-size: 24px;
    color: #f43259;
    margin-bottom: 10px;
}

@media screen and (max-width: 1280px) {
    .module {
        width: 60%;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 600px) {
    .module {
        width: 90%;
        margin-bottom: 30px;
    }
}
</style>
